import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.scss';
import "leaflet/dist/leaflet.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import Loading from './common/Loading';
const Home = React.lazy(() => import('./pages'));
const WhoWeAre = React.lazy(() => import('./pages/WhoWeAre'));
const OurApproach = React.lazy(() => import('./pages/OurApproach'));
const OurFocus = React.lazy(() => import('./pages/OurFocus'));
const WhatWeDo = React.lazy(() => import('./pages/WhatWeDo'));
const ContactUs = React.lazy(() => import('./pages/ContactUs'));
const News = React.lazy(() => import('./pages/News'));
const DetailNews = React.lazy(() => import('./pages/News/DetailNews'));
const OurPartners = React.lazy(() => import('./pages/OurPartners'));
const Project = React.lazy(() => import('./pages/OurProject'));
const DetailProject = React.lazy(() => import('./pages/OurProject/DetailProject'));
const OurTeam = React.lazy(() => import('./pages/OurTeam'));
const Publications = React.lazy(() => import('./pages/Publications'));
const DetailPublications = React.lazy(() => import('./pages/Publications/DetailPublications'));
const ProjectUpdates = React.lazy(() => import('./pages/ProjectUpdates'));

const App = () => {
  // <ScrollToTop />
  let routes = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/our-approach",
      element: <OurApproach />,
    },
    {
      path: "/our-focus",
      element: <OurFocus />,
    },
    {
      path: "/what-we-do",
      element: <WhatWeDo />,
    },
    {
      path: "/who-we-are",
      element: <WhoWeAre />,
    },
    {
      path: "/contact-us",
      element: <ContactUs />,
    },
    {
      path: "/news",
      element: <News />,
    },
    {
      path: "/news/:title/:id",
      element: <DetailNews />,
    },
    {
      path: "/our-partners",
      element: <OurPartners />,
    },
    {
      path: "/our-project",
      element: <Project />,
    },
    {
      path: "/our-project/:title/:id",
      element: <DetailProject />,
    },
    {
      path: "/our-team/:name/:id",
      element: <OurTeam />,
    },
    {
      path: "/publications/",
      element: <Publications />,
    },
    {
      path: "/publications/:title/:id",
      element: <DetailPublications />,
    },
    {
      path: "/updates-project/:title/:id/:idproject",
      element: <ProjectUpdates />,
    },
    // ...
  ]);
  return routes;
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <StrictMode>
  <React.Suspense fallback={<Loading />}>
    <BrowserRouter >
      <App />
    </BrowserRouter>
  </React.Suspense>
  // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
