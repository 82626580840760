import { Spinner } from "react-bootstrap";

export default function Loading() {
    return (
        <div className="loading-container">
            <Spinner animation="border" role="status">
            </Spinner>
            <h4 className="ms-3 fw-bold mb-0">Loading...</h4>
        </div>
    )
}